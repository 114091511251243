import { useRevisionProviderStore } from "~/stores/admin/providers";

type ItemProperty = {
  [key: string]: string;
};

export function useStoreComposable() {
  const itemProperties: ItemProperty = {
    banks: "transactions",
    personal: "transactions",
    // balance: "blocks",
    // ledger: "blocks",
    library: "documents",
    products: "products",
    sales: "items",
    providersLedger: "provLedgerItems",
    employees: "items",
    // societyRevision: "items",
    tiers: "tiers",
    vat: "items",
    das2: "items",
    ztickets: "zTickets",
    admin: "admin",
    adminSocieties: "adminSocieties",
    auxiliaryLedger: "data",
    generalLedger: "data",
    balance: "data",
    payments: "data",
    category: "chartAccounts",
    purchases: "items",
    companyTaxAdvances: "data",
    balanceSheet: "data",
    balanceSheetDocuments: "filteredDocuments",
    adminRevision: "items",
    recovery: "items",
  };

  function activeStore(state?: string) {
    switch (state) {
      case "recovery":
        return useRecoveryStore();
      case "products":
        return useProductStore();
      case "banks":
      case "personal":
        return useBankStore();
      case "sales":
        return useSalesStore();
      case "customers":
      case "providers":
        return useThirdPartiesStore();
      case "ztickets":
        return useZTicketsStore();
      case "library":
        return useLibraryStore();
      case "adminSocieties":
        return useAdminSocietiesStore();
      case "auxiliaryLedger":
        return useAuxiliaryLedgerStore();
      case "generalLedger":
        return useGeneralLedgerStore();
      case "balance":
        return useBalanceStore();
      case "employees":
        return useEmployeesStore();
      case "vat":
      case "adminVat":
        return useVatStore();
      case "das2":
      case "adminDas2":
        return useDas2Store();
      case "payments":
        return usePaymentStore();
      case "category":
        return useCategoryStore();
      case "purchases":
        return usePurchasesStore();
      case "adminSociety":
        return useAdminSocietyStore();
      case "preCompta":
        return usePreComptaStore();
      case "pendingPoints":
        return usePendingPointsStore();
      case "revisionGuide":
        return useRevisionGuideStore();
      case "companyTaxAdvances":
        return useCompanyTaxAdvanceStore();
      case "balanceSheet":
        return useBalanceSheetStore();
      case "balanceSheetDocuments":
        return useBalanceSheetDocumentsStore();
      case "specificAccountingPlan":
        return useSpecificAccountingPlanStore();
      case "persoAccountingPlan":
        return usePersoAccountingPlanStore();
      case "genericAccount":
        return useGenericAccountStore();
      case "adminRevision":
        return useRevisionStore();
      case "adminRevisionCustomer":
        return useRevisionCustomerStore();
      case "adminRevisionProvider":
        return useRevisionProviderStore();
      case "expertAccountant":
        return useExpertAccountantStore();
      default:
        return null;
    }
  }

  return { activeStore, itemProperties };
}
