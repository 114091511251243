import cloneDeep from "lodash-es/cloneDeep";

export type AdminSocietyQueryPropertie = {
  tab: "préCompta" | "Révision" | "Utilisateurs" | "Informations" | null;
  upperTab: "Révision" | "Édition";
  search: string | undefined;
  page: number;
  limit: number;
  userId: number | null | undefined;
  startDate: string | null;
  endDate: string | null;
};

export const defaultAdminSocietyQueryProperties = ref<AdminSocietyQueryPropertie>({
  tab: null,
  upperTab: "Édition",
  page: 1,
  limit: 25,
  search: undefined,
  userId: undefined,
  startDate: null,
  endDate: null,
});

type AdminSocietyState = {
  queryProperties: AdminSocietyQueryPropertie;
  isLoading: boolean;
  hasAnyItem: boolean;
};

export const useAdminSocietyStore = defineStore("adminSociety", {
  state: () =>
    ref<AdminSocietyState>({
      queryProperties: cloneDeep(
        defaultAdminSocietyQueryProperties.value,
      ) as AdminSocietyQueryPropertie,
      isLoading: false,
      hasAnyItem: true,
    }),
});
